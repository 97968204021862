import React from "react"
import Layout from "../components/Layout"
import Accordion from "../components/ui/Accordion"
import Block from "../components/ui/Block"
import Button from "../components/ui/Button"
import Card from "../components/ui/Card"
import CTA from "../components/ui/CTA"
import FlexContainer from "../components/ui/FlexContainer"
import { graphql } from "gatsby"

export default function Sportarten({ data }) {
  const pageContext = {
    seo: {
      title: "Turnverein Lahr",
      description:
        "Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr.",
      robots: ["index, follow"],
      path: "/news",
      lang: "de",
    },
  }
  const news = data?.allDatoCmsNews.nodes
  return (
    <Layout pageContext={pageContext}>
      <Block
        note="Aktuelles vom TV Lahr"
        title="News"
        // content="Hier findest du alle Sportarten, die wir aktuell anbieten. Wir unterteilen das Angebot in Wettkampf- und Freizeitsport."
      />
      <FlexContainer gap="24px" className="main-container">
        {news?.map(item => (
          <Card
            imageUrl={item?.media?.url}
            title={item?.title}
            content={item?.subtitle}
            note={item?.meta?.firstPublishedAt}
            href={`/news/${item?.meta?.publishedAt}/`}
          />
        ))}
      </FlexContainer>
      <CTA />
    </Layout>
  )
}
export const query = graphql`
  query NewsQuery {
    allDatoCmsNews(sort: {fields: meta___publishedAt, order: DESC}) {
      nodes {
        media {
          alt
          url
        }
        subtitle
        title
        meta {
          publishedAt(formatString: "yyyy-MM-DD-HH-mm")
          firstPublishedAt(formatString: "DD.MM.yyyy")
        }
      }
    }
  }
`
